import React from 'react'

const Window = ({className}) => {
   return (
      <path id="window"
        className={className}
        d="M 102.88,29.62
           C 102.88,29.62 112.25,21.75 112.25,21.75
             112.25,21.75 130.62,21.75 130.62,21.75
             135.00,22.12 147.91,25.63 132.00,31.00
             124.38,31.00 115.62,30.88 115.62,30.88
             115.62,30.88 115.66,28.62 115.66,28.62
             115.66,28.62 118.50,28.62 118.50,28.62
             118.50,28.62 118.56,25.41 118.56,25.41
             118.56,25.41 109.75,25.41 109.75,25.41
             109.75,25.41 109.72,28.53 109.72,28.53
             109.72,28.53 110.69,28.50 110.69,28.50
             110.69,28.50 110.75,29.72 110.75,29.72
             110.75,29.72 102.88,29.62 102.88,29.62 Z" />
   )
}

export default Window

