import React from 'react'

const BigO = ({className}) => {
  return (
    <path id="BigO"
        className={className}
        fill="none" stroke="black" stroke-width="1"
        d="M 82.23,62.00
           C 75.67,62.00 70.32,59.79 66.19,55.36
             62.05,50.92 59.98,44.98 59.98,37.55
             59.98,30.45 61.96,24.58 65.91,19.95
             69.86,15.32 75.35,13.00 82.38,13.00
             88.86,13.00 94.15,15.18 98.23,19.55
             102.33,23.91 104.38,29.74 104.38,37.03
             104.38,44.57 102.31,50.62 98.19,55.17
             94.06,59.73 88.74,62.00 82.23,62.00 Z
           M 82.16,51.00
           C 84.57,51.00 86.31,49.95 87.38,47.83
             88.44,45.71 88.97,41.84 88.97,36.22
             88.97,28.07 86.79,24.00 82.44,24.00
             77.74,24.00 75.39,28.64 75.39,37.92
             75.39,46.64 77.64,51.00 82.16,51.00 Z" />
  )
}

export default BigO

