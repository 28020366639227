import React from 'react'

const M = ({className}) => {
   return (
      <path id="M"
      className={className}
        fill="none" stroke="black" stroke-width="1"
        d="M 177.14,21.00
           C 177.14,21.00 177.14,54.00 177.14,54.00
             177.14,54.00 167.64,54.00 167.64,54.00
             167.64,54.00 167.64,30.41 167.64,30.41
             167.64,30.41 161.47,54.00 161.47,54.00
             161.47,54.00 154.97,54.00 154.97,54.00
             154.97,54.00 148.58,30.41 148.58,30.41
             148.58,30.41 148.58,54.00 148.58,54.00
             148.58,54.00 140.91,54.00 140.91,54.00
             140.91,54.00 140.91,21.00 140.91,21.00
             140.91,21.00 154.27,21.00 154.27,21.00
             154.27,21.00 159.09,38.53 159.09,38.53
             159.09,38.53 163.70,21.00 163.70,21.00
             163.70,21.00 177.14,21.00 177.14,21.00 Z" />
   )
}

export default M

