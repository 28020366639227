import React from 'react'

const Z = ({className, animate}) => {
   return (
      <path id="Z"
      className={className}
        fill="none" stroke="black" stroke-width="1"
        d="M 57.56,45.00
           C 57.56,45.00 57.56,53.00 57.56,53.00
             57.56,53.00 29.83,53.00 29.83,53.00
             29.83,53.00 29.83,46.00 29.83,46.00
             29.83,46.00 44.59,28.00 44.59,28.00
             44.59,28.00 30.95,28.00 30.95,28.00
             30.95,28.00 30.95,20.00 30.95,20.00
             30.95,20.00 57.09,20.00 57.09,20.00
             57.09,20.00 57.09,27.00 57.09,27.00
             57.09,27.00 42.77,45.00 42.77,45.00
             42.77,45.00 57.56,45.00 57.56,45.00 Z" />
   )
}

export default Z

