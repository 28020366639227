import React from 'react'

const Spokes = ({className, back, spin}) => {
 const d
 =
 back 
 ?
    `
    M 242.44,44.47
    C 242.44,44.47 242.57,35.73 242.57,35.73M 242.31,54.00
    C 242.31,54.00 242.31,61.56 242.31,61.56M 237.52,49.08
    C 237.52,49.08 229.39,49.04 229.39,49.04M 247.12,49.14
    C 247.12,49.14 254.90,49.23 254.90,49.23M 245.78,45.60
    C 245.78,45.60 251.13,39.43 251.13,39.43M 245.64,52.58
    C 245.64,52.58 251.14,57.33 251.14,57.33M 239.13,52.78
    C 239.13,52.78 233.75,58.28 233.75,58.28M 239.14,45.70
    C 239.14,45.70 233.51,39.57 233.51,39.57M 240.66,44.91
    C 248.57,43.69 248.30,51.72 244.22,53.34
      239.56,55.20 234.61,49.15 240.66,44.91 Z
    M 239.00,34.49
    C 240.95,34.35 241.97,34.12 244.00,34.49
      262.84,36.21 260.10,64.84 241.00,62.93
      225.96,61.42 222.43,39.97 239.00,34.49 Z
    `
 :
`
M 59.44,44.35
           C 59.44,44.35 59.57,35.61 59.57,35.61M 59.31,53.88
           C 59.31,53.88 59.31,61.44 59.31,61.44M 54.52,48.96
           C 54.52,48.96 46.39,48.91 46.39,48.91M 64.12,49.02
           C 64.12,49.02 71.90,49.10 71.90,49.10M 62.78,45.48
           C 62.78,45.48 68.13,39.31 68.13,39.31M 62.64,52.46
           C 62.64,52.46 68.14,57.21 68.14,57.21M 56.13,52.66
           C 56.13,52.66 50.75,58.16 50.75,58.16M 56.14,45.57
           C 56.14,45.57 50.51,39.45 50.51,39.45M 57.66,44.78
           C 65.57,43.57 65.30,51.60 61.22,53.22
             56.56,55.07 51.61,49.02 57.66,44.78 Z
           M 56.00,34.36
           C 57.95,34.23 58.97,33.99 61.00,34.36
             79.84,36.09 77.10,64.72 58.00,62.80
             42.96,61.29 39.43,39.84 56.00,34.36 Z
`

  return (
    <path id={`${back ? 'back' : 'front'}-tire`}
        className={className}
        d={d} >
    </path>

  )
}

export default Spokes